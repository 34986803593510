<template>
    <div>
        <!-- <div class="r-option-place r-center-flex r-isWrap">
            <InputCustom
                :rightIcon="true"
                :withError="false"
                v-model="request.search" 
                @keyup="setTime()" 
                @keydown="clearTime()" 
                @keydown.tab="setTime()" 
                @keyup.tab="clearTime()" 
                @keypress.enter="getData()" 
                placeholder="Search . . ."
                size='md'
                >
                <template #rightIcon>
                    <div class="y-inputIcon r-center-flex">
                        <img :src="require('@/assets/icons/others/search.svg')" alt="search">
                    </div>
                </template>
            </InputCustom>
        </div> -->

        <div class="r-table">
            <table>
                <thead>
                    <tr class="hideThead" :class="showThead ? 'showThead' : ''">
                        <th class="r-table-firstColumn">No</th>
                        <th class="r-table-unsortable">Periode</th>
                        <th class="r-table-unsortable">Hari</th>
                        <th class="r-table-unsortable">Tanggal</th>
                        <th class="r-table-unsortable">Jam Buka</th>
                        <th class="r-table-unsortable">Jam Tutup</th>
                        <th class="r-table-actionColumn" style="min-width: 200px;">Nomor</th>
                        <th class="r-table-actionColumn">Action</th>
                    </tr>
                </thead>
                <loading v-if="fetchData"/>
                <nodata v-if="data.length == 0"></nodata>
                <tr v-for="(item, index) in data" :key="`${index}-periode`">
                    <td data-label="No" class="r-table-firstColumn" >{{curNumber(index)}}</td>
                    <td data-label="Periode" >{{item.id_periode}}</td>
                    <td data-label="Hari" >{{item.hari}} </td>
                    <td data-label="Tanggal" >{{item.tanggal}} </td>
                    <td data-label="Jam Buka" >{{item.jam_buka}} </td>
                    <td data-label="Jam Tutup" >{{item.jam_tutup}} </td>
                    <td data-label="Nomor" >
                        <div class="y-inputField">
                            <div class="y-hasIcon-right y-lr-size">
                                <input type="number" maxlength="4" v-model="item.nomor">
                            </div>
                        </div>
                    </td>
                    <td data-label="Action">
                        <button class="y-purple-outline y-purple-text y-white-bg y-md-size" 
                            @click.prevent.stop="goToAnalisa(item)"
                        >
                            Analisa
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import tableMethods from '@/mixins/tableMethods'
import common from '@/mixins/function/common'
import tableMixins from '@/mixins/tableMixins'
export default {
    mixins: [
        common, 
        tableMixins,
        tableMethods
    ],
    components: {
    },
    mounted() {
        this.initData();
    },
    computed: {
        ...mapGetters ({
            request: 'periode/getRequest',
            item: 'periode/getItem',
        }),
    },
    data: () => ({
        showThead: false,
        data: {
            last_page: 0,
            data: [],
            total: 0,
        },
        modal: null,
        inputType: 'Tambah',
    }),
    methods: {
        ...mapActions ({
            resetItem: 'periode/resetItem',
            resetRequest: 'periode/resetRequest',
            setItem: 'periode/setItem',
            getPeriodes: 'periode/getPeriode',
        }),
        resetData() {
            this.modal = null
            this.resetItem()
            this.resetRequest()
        },
        async getData() {
            this.fetchData = true
            await this.getPeriodes()
            this.fetchData = false
            const response = this.getResponse('periode')
            if (response.status !==1) {
                this.showSnackbar ({
                    type: 'error',
                    text: response.msg
                })
            } else {
                this.data = this.$store.getters[`periode/getPeriodes`]
            }
        },
        async initData() {
            const breadcrumb = [{
                name: 'Periode',
                link: {name: null},
                current: true
            }]
            this.setActiveTab('Setting Periode')
            this.setBreadCrumb(breadcrumb)
            await this.getData()
        },
        goToAnalisa(item) {
            this.$router.push({ name: 'ViewAnalisaNomor', params: { id: item.id, nomor: item.nomor } })
        },
        // validate(){
        //     var error = 0
        //     if(this.item.max !== null && this.item.max !== ""){
        //         if(!(/^\d+$/.test(this.item.max))){
        //             this.error.max = true
        //             this.errorMsg.max = "Hanya boleh angka"
        //             error += 1;
        //         } else{
        //             this.error.max = false
        //         }
        //     }
        //     if(this.item.diskon !== null && this.item.diskon !== ""){
        //         if(!(/^\d+$/.test(this.item.diskon))){
        //             this.error.diskon = true
        //             this.errorMsg.diskon = "Hanya boleh angka"
        //             error += 1;
        //         } else if (this.item.diskon < 0 || this.item.diskon > 100){
        //             this.error.diskon = true
        //             this.errorMsg.diskon = "Range diskon 1 - 100"
        //             error += 1;
        //         } else{
        //             this.error.diskon = false
        //         }
        //     }
        //     if(this.item.kei !== null && this.item.kei !== ""){
        //         if(!(/^\d+$/.test(this.item.kei))){
        //             this.error.kei = true
        //             this.errorMsg.kei = "Hanya boleh angka"
        //             error += 1;
        //         } else{
        //             this.error.kei = false
        //         }
        //     }
        //     if(this.item.menang !== null && this.item.menang !== ""){
        //         if(!(/^\d+$/.test(this.item.menang))){
        //             this.error.menang = true
        //             this.errorMsg.menang = "Hanya boleh angka"
        //             error += 1;
        //         } else{
        //             this.error.max = false
        //         }
        //     }
        //     return error
        // },
    }
}
</script>